.ct-legend {
    list-style: outside none none;
    position: relative;
    text-align: center;
    z-index: 10;
}

.ct-legend li {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 10px;
    padding-left: 23px;
    position: relative;
}

.ct-legend li::before {
    border: 3px solid transparent;
    border-radius: 2px;
    content: "";
    height: 12px;
    left: 0;
    position: absolute;
    width: 12px;
    margin-top: 6px;
}

.ct-legend li.inactive::before {
    background: transparent none repeat scroll 0 0;
}

.ct-legend.ct-legend-inside {
    position: absolute;
    right: 0;
    top: 0;
}

.ct-legend.ct-legend-inside li {
    display: block;
    margin: 0;
}

.ct-legend .ct-series-0::before {
    background-color: #d70206;
    border-color: #d70206;
}

.ct-legend .ct-series-1::before {
    background-color: #f05b4f;
    border-color: #f05b4f;
}

.ct-legend .ct-series-2::before {
    background-color: #f4c63d;
    border-color: #f4c63d;
}

.ct-legend .ct-series-3::before {
    background-color: #d17905;
    border-color: #d17905;
}

.ct-legend .ct-series-4::before {
    background-color: #453d3f;
    border-color: #453d3f;
}