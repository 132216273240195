.media-body .lead {
    margin-top: 0;
}

.bip-img-full .img-responsive {
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

#icons-panel .fa {
    margin-bottom: 12px;
}

.caption {
    color: #333;
    font-size: 14px;
}

#map_leaflet_geo {
    height: 360px;
    margin-bottom: 36px;
    width: 100%;
}

@media (max-width: 767px) {
    .media-left, .media-right, .media-body {
        display: block;
        width: 100%;
    }
}

@media (min-width: 1200px) {
    #extraModal .modal-content{
        border-radius: 2px;
        font-size: 26px;
    }

    #extraModal h4 {
        font-weight: bold;
        font-size: 32px;
    }
}