html,
body {
    height: 100%;
    font-family: 'Calibri', sans-serif;;
    font-size: 16px;
    line-height: 1.3;
}

h1, h2, h3, h4, h5, h6, .nz_h3 {
    font-family: 'Cormorant Garamond', sans-serif;;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    color: #7d5514;
}

h1 {
    font-size: 60px;
    font-weight: normal;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 15px;
}

h2 {
    font-size: 38px;
    font-weight: normal;
    padding: 0 0 4px 18px;
    text-align: center;
    color: #7d5514;
}

a {
    /*background-color: #000;*/
    /*color: #FFD40F;*/
    color: #7d5514;
}

a:focus, a:hover, a:active {
    text-decoration: underline;
    color: #7d5514;
    /*background-color: #7d5514;*/
}

article .lead {
    font-size: 18px;
    margin-top: 20px;
    font-weight: 500;
}

.container-mine {
    width: 100%;
}

.header {
    background-color: #FFF;
    color: #000;
}

.home_grid {
    margin: auto;
}

.home_grid_padding {
    padding-right: 0;
    padding-left: 0;
}

/*.intro_, .news_, .events_, .gallery_, .multimedia_, .socialmedia_, .count_down_, .plain_panel_ {*/

/*}*/

.icons_panel_ {
    width: 100%;
    background-color: #FFF;
}

.rodzic_, .trener_ {
    width: 100%;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    color: #000;
    margin: 0;
    padding: 0;
}

.bramkarz_ {
    width: 100%;
    background-color: #7d5514;
    color: #FFF;
    margin: 0;
    padding: 0;
}

.rodzic_ .menu_colapse_sm .list-group-item, .trener_ .menu_colapse_sm .list-group-item {
    background-color: #7d5514;
    color: #fff;
    border: 0px;
}

.bramkarz_ .menu_colapse_sm .list-group-item {
    background-color: #7d5514;
    color: #FFF;
    border: 0px;
}

.bramkarz_ h2 {
    color: #FFF;
}

.bramkarz_ a {
    color: #FFF;
}

.bramkarz_ a:hover, .bramkarz_ a:active, .bramkarz_ a:focus {
    background-color: #7d5514;
    color: #FFF;
}

.rodzic_ a, .trener_ a {
    color: #7d5514;
}

.rodzic_ a:hover, .rodzic_ a:active, .rodzic_ a:focus, .trener_ a:hover, .trener_ a:active, .trener_ a:focus {
    background-color: #7d5514;
    color: #fff;
}

.rodzic_ .text-uppercase, .bramkarz_ .text-uppercase {
    font-family: "Centaur", sans-serif;
    font-size: 38px;
    font-weight: normal;
    text-transform: initial;
    padding-top: 20px;
}

.navbar {
    border-radius: 0;
}

.carousel-caption .slide_title {
    color: #FFF;
    font-size: 38px;
    font-weight: normal;
    font-family: "Centaur", sans-serif;
    text-shadow: 0px 0px 7px rgba(0, 0, 0, 1);
    display: none;
}

.carousel-caption .slide_title span {
    background-color: #000;
    opacity: 0.7;
    padding: 10px 10px 30px 10px;
}

.carousel-caption .slide_title a {
    color: #FFF;
}

.carousel-caption .slide_title a:hover {
    color: #FFF;
    text-decoration: none;
}

.carousel-caption .slide_lead {
    color: #FFF;
    font-size: 18px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
    background-color: #000;
    opacity: 0.7;
    display: none;
}

.btn-info {
    background-color: #464749;
    border: 0 none;
    color: #FFF;
}

#menu-ogolne {
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset, 0 1px 5px rgba(0, 0, 0, 0.3);
    margin: 0;
    min-height: 24px;
    padding: 0;
}

#menu-ogolne .navbar-brand {
    float: left;
    font-family: "Calibri", sans-serif;
    font-size: 14px;
    height: 20px;
    line-height: 16px;
    padding: 8px 0 0 12px;
}

#menu-ogolne .nav > li > a {
    padding: 8px 10px 4px;
}

#mySlideShow {
    width: 100%;
    margin: 0;
    padding: 0;
}

#mySlideShow a:hover {
    background-color: transparent;
}

.img-portfolio {
    margin-bottom: 30px;
}

.img-hover:hover {
    opacity: 0.8;
}

.carousel {
    height: 520px;
    background-color: #000;
    margin-top: 0;
    padding: 0;
}

.carousel .item,
.carousel .item.active,
.carousel .carousel-inner {
    height: 100%;
    margin: 0;
    padding: 0;
}

.carousel .fill {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

.carousel-indicators li {
    border: 1px solid #FFF;
}

.carousel-indicators .active {
    background-color: #FFF;
}

footer {
    margin: 20px 0 0 0;
    padding-bottom: 30px;
    color: #FFF;
    background-color: #000;
    font-size: 14px;
}

footer .list-group-item {
    background-color: transparent;
    border: 0;
}

footer .list-group-item a {
    color: #FFF;
}

footer .list-group .list-group-item {
    padding: 0 2px 0 15px;
}

footer a {
    color: #FFF;
}

footer a:hover {
    background-color: #EFEFEF;
    color: #000;
    text-decoration: underline;
}

footer a.list-group-item {
    color: #FFF;
}

footer .none-blink a:hover {
    background-color: transparent;
    color: #FFF;
    text-decoration: none;
}

.breadcrumb {
    padding: 2px 15px;
    font-size: 12px;
    margin-bottom: 0;
    text-align: right;
}

.breadcrumb ul {
    margin-bottom: 0;
}

.breadcrumb .list-inline > li {
    padding-right: 0;
}

.breadcrumb li:before {
    color: #000;
    content: "\002F\00a0";
}

#icons-panel {
    background-color: #FFF;
    color: #000;
    margin-top: 20px;
    padding-bottom: 20px;
}

#icons-panel h2 {
    /*border-color: #fff;*/
    color: #7d5514;
}

#icons-panel h3 {
    /*border-color: #fff;*/
    color: #000;
    text-transform: none;
}

#icons-panel h3 img {
    padding-bottom: 15px;
}

#icons-panel a {
    color: #7d5514;
}

#map_geo {
    height: 360px;
    margin-bottom: 36px;
    width: 100%;
}

.full-width {
    width: 100%;
}

.content-on-home {
    margin: 0 0 0 0;
}

.content-on-home h3 {
    margin-top: 6px;
}

.news-list-img {
    max-width: 360px;
}

.f_contact {
    margin-right: -11px;
    margin-left: -11px;
}

@media (max-width: 767px) {
    .img-portfolio {
        margin-bottom: 15px;
    }

    .carousel .carousel {
        height: 70%;
    }

    footer .custom-collapse {
        border: 1px solid #fff;
        border-radius: 3px;
    }
}

@media (min-width: 767px) {
    .menu_colapse_sm .custom-collapse .collapse {
        display: block;
        visibility: visible;
    }
}

@media (max-width: 992px) {
    .customer-img,
    .img-related {
        margin-bottom: 30px;
    }

}

@media (min-width: 1200px) {
    .eq-row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
    }

    .eq-row [class*='col-'] {
        display: flex;
        flex-direction: column;
    }

    .eq-row [class*='col-'] .custom-collapse {
        height: 100%;
    }

    .modal-dialog {
        width: 900px;
    }
}

#gallery .square {
    background: #eee no-repeat center;
    background-size: auto 100%;
}

#gallery .double {
    background: #eee no-repeat center;
    background-size: 100% auto;
}

#gallery .tresc {
    background: #000;
    opacity: 0.8;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 15px;
}

#gallery .tresc h3 {
    margin: 0;
    font-weight: normal;
}

#gallery .tresc h3 a {
    color: #fff;
    font-size: 22px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    margin: 0;
    opacity: 1;
}

.multi {
    background-color: #464749;
    padding-top: 15px;
}

.multi h2 {
    color: #fff;
}

.multi .row {
    margin: 10px;
}

.multi a {
    color: #fff;
}

.btn-info:hover, .btn-info:focus, btn-info:active {
    color: #fff;
    background-color: #7d5514;
    border-color: #7d5514;
}

.form-group {
    padding: 11px 0 11px 0;
}

.alert-info {
    color: #fff;
    background-color: #7d5514;
    border-color: #7d5514;
}

.pagination > li > a, .pagination > li > span {
    color: #000;
    background-color: #fff;
    border: 1px solid #CCC;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    color: #fff;
    cursor: default;
    background-color: #7d5514;
    border-color: #7d5514;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
    color: #fff;
    background-color: #7d5514;
    border-color: #7d5514;
}

hr {
    border-top: 1px solid #ccc;
}

.pagination-special {
    clear: both;
}

.tile {
    background-color: #7d5514;
    color: #fff;
    border: 3px solid #fff;
    height: 150px;
    padding: 20px;
}

.img-thumbnail {
    padding: 10px 2px 10px 2px;
    background-color: #fff;
    border: 0px;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    margin: 0px;
}

#cookies .btn-warning {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

#cookies .btn-warning:hover {
    color: #000;
    background-color: #fff;
    border-color: #000;
}

#cookies .alert-warning {
    color: #000000;
    background-color: #f2f2f2;
    border-color: #cccc;
}