#main_menu {
    background-color: #fff;
    width: 100%;
    color: #000;
    font-weight: normal;
    text-transform: uppercase;
}

/*#main_menu a {*/
/*color: #FFF;*/
/*}*/

#main_menu > ul ul {
    font-family: 'Calibri', sans-serif;
    font-weight: normal;
}

.navbar-default .navbar-nav > li > a {
    color: #000;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
    color: #FFF;
    background-color: #7d5514;
}

.navbar-default {
    border-color: #fff;
    color: #000;
}

.dropdown-menu > li > a {
    color: #000;
    font-weight: normal;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #FFF;
}

.navbar-default .navbar-toggle {
    border-color: #FFF;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #000;
}

@media (max-width: 767px) {
    #main_menu.affix {
        position: static;
    }

    #main_menu .dropdown-menu > li > a {
        text-align: center;
        color: #FFF;
    }

    #main_menu .dropdown-menu > li > a:hover {
        background-color: #FFF;
        color: #000;
    }

    #menu-ogolne .list-inline {
        margin-bottom: 2px;
        margin-top: 6px;
    }

    #wyszukiwarka {
        margin: 8px 0 0;
    }

    #wyszukiwarka form {
        margin: 0;
        padding: 0;
    }

    #wyszukiwarka .navbar-nav > li > a {
        padding: 1px 0 0 6px;
    }

    #wyszukiwarka .list-inline > li {
        padding: 5px 0 0 10px;
    }

    #main_menu .input-group-addon {
        padding: 0;
    }

    #main_menu .input-group-addon .btn-block {
        background-color: transparent;
        padding: 0;
        width: 32px;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu > .dropdown-menu {
        border-radius: 0 6px 6px;
        left: 100%;
        margin-left: -1px;
        margin-top: -6px;
        top: 0;
    }

    .dropdown-submenu > a::after {
        border-color: transparent transparent transparent #ccc;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        content: " ";
        display: block;
        float: right;
        height: 0;
        margin-right: -10px;
        margin-top: 5px;
        width: 0;
    }

    .dropdown-submenu:hover > a::after {
        border-left-color: #FFF;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        border-radius: 6px 0 6px 6px;
        left: -100%;
        margin-left: 10px;
    }

    .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
        background-color: #7d5514;
        color: #fff;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    #menu-ogolne .list-inline {
        margin-bottom: 2px;
        margin-top: 6px;
    }

    #wyszukiwarka {
        margin: 25px 0 0;
    }

    #wyszukiwarka form {
        margin: 0;
        padding: 0;
    }

    #wyszukiwarka .navbar-nav > li > a {
        padding: 1px 0 0 6px;
    }

    #wyszukiwarka .list-inline > li {
        padding: 5px 0 0 10px;
    }

    #main_menu .input-group-addon {
        padding: 0;
    }

    #main_menu .navbar-form.navbar-right:last-child {
        margin: 0;
    }

    #main_menu .navbar-form .form-group {
        display: block;
    }

    #main_menu .input-group-addon .btn-block {
        background-color: transparent;
        padding: 0;
        width: 32px;
    }

    #main_menu .navbar-form {
        padding-right: 0;
        padding-top: 0;
        width: 100%;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu > .dropdown-menu {
        border-radius: 0 6px 6px;
        left: 100%;
        margin-left: -1px;
        margin-top: -6px;
        top: 0;
    }

    .dropdown-submenu > a::after {
        border-color: transparent transparent transparent #FFD40F;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        content: " ";
        display: block;
        float: right;
        height: 0;
        margin-right: -10px;
        margin-top: 5px;
        width: 0;
    }

    .dropdown-submenu:hover > a::after {
        border-left-color: #FFF;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        border-radius: 6px 0 6px 6px;
        left: -100%;
        margin-left: 10px;
    }

    #main_menu.affix {
        border-bottom: 0 none;
        box-shadow: 0 0 15px #888;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }

    #main_menu.affix ul.navbar-nav {
        border: 0 none;
    }

    #main_menu .go_top {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    #main_menu.affix .go_top {
        position: absolute;
        right: 10px;
        top: 0;
        visibility: visible;
        opacity: 1;
    }

    #main_menu ul.navbar-nav > #to_home {
        height: auto;
    }

    #main_menu ul.navbar-nav > #to_home:hover {
        background-color: transparent;
    }

    #main_menu ul.navbar-nav > #to_home:hover a {
        background-color: transparent;
    }

    #main_menu ul.navbar-nav > #to_home img {
        position: relative;
        top: -2px;
        height: 22px;
    }

    #main_menu ul.navbar-nav > #to_home {
        position: absolute;
        top: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
    }

    #main_menu.affix ul.navbar-nav > #to_home {
        position: relative;
        visibility: visible;
        opacity: 1;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    #main_menu.affix ul.navbar-nav > li {

    }

    #main_menu.affix ul.navbar-nav > li > a {
        margin-top: 0px;
    }

    .dropdown-menu {
        background-color: #7d5514;
        color: #fff;
    }

    .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
        background-color: #7d5514;
        color: #fff;
    }

    .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
        background-color: #7d5514;
        color: #fff;
    }

    .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
        background-color: #7d5514;
        color: #fff;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    #menu-ogolne .list-inline {
        margin-bottom: 2px;
        margin-top: 6px;
    }

    #wyszukiwarka {
        margin: 4px 0 0;
    }

    #wyszukiwarka form {
        margin: 0;
        padding: 0;
    }

    #wyszukiwarka .navbar-nav > li > a {
        padding: 1px 0 0 6px;
    }

    #wyszukiwarka .list-inline > li {
        padding: 5px 0 0 10px;
    }

    .navbar-default {
        background-color: #323582;
        border: 0 none;
    }

    .logo {
        padding-top: 2px;
    }

    #main_menu {
        margin-bottom: 0;
    }

    #main_menu .navbar-collapse {
        padding: 0;
    }

    #main_menu > ul > li {
        border-left: 1px solid #000;
        height: 60px;
        padding-top: 10px;
    }

    #main_menu > ul > li:last-child {
        border-right: 1px solid #000;
    }

    #main_menu > ul > li > a {
        color: #FFF;
        padding-bottom: 0;
        padding-top: 0;
    }

    #main_menu {
        height: 60px;
        min-height: 60px;
    }

    #main_menu li.active, #main_menu li.active > a {
        background-color: #FFF;
        color: #000;
    }

    #main_menu .form-control, #main_menu .input-group-addon {
        background-color: #7d5514;
        border: 0 none;
        box-shadow: none;
        color: #FFF;
        padding: 0;
    }

    #main_menu .input-group-addon .btn-block {
        background-color: #FFF;
        width: 32px;
    }

    #main_menu .navbar-form {
        margin-top: 5px;
        padding-right: 0;
        padding-top: 0;
        width: 240px;
    }

    #main_menu ul.navbar-nav {
        border-bottom: 1px solid #000;
        border-top: 0px solid #000;
        clear: both;
        display: table;
        height: 40px;
        line-height: 40px;
        list-style: outside none none;
        margin: 0 0 1px;
        padding: 0;
        text-align: center;
        width: 100%;
    }

    #main_menu ul.navbar-nav > li {
        display: table-cell;
        float: none;
        font-family: 'Calibri', sans-serif;
        font-size: 14px;
    }

    #main_menu ul.navbar-nav ul {
        font-family: 'Calibri', sans-serif;
        background-color: #7d5514;
    }

    #main_menu ul.navbar-nav > li > a {
        color: #000;
        display: block;
        height: 100%;
        text-decoration: none;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #main_menu ul.navbar-nav > li:hover > a {
        background-color: #7d5514;
        color: #fff;
    }

    #main_menu ul.navbar-nav > li.active > a {
        background-color: #7d5514;
        color: #fff;
    }

    #main_menu ul.navbar-nav ul {
        border: 0 none;
        text-align: left;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu > .dropdown-menu {
        border-radius: 0 6px 6px;
        left: 100%;
        margin-left: -1px;
        margin-top: -6px;
        top: 0;
    }

    .dropdown-submenu > a::after {
        border-color: transparent transparent transparent #FFD40F;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        content: " ";
        display: block;
        float: right;
        height: 0;
        margin-right: -10px;
        margin-top: 5px;
        width: 0;
    }

    .dropdown-submenu:hover > a::after {
        border-left-color: #FFF;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        border-radius: 6px 0 6px 6px;
        left: -100%;
        margin-left: 10px;
    }

    #main_menu.affix {
        border-bottom: 0 none;
        box-shadow: 0 0 15px #888;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }

    #main_menu.affix ul.navbar-nav {
        border: 0 none;
    }

    #main_menu .go_top {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    #main_menu.affix .go_top {
        position: absolute;
        right: 10px;
        top: 0;
        visibility: visible;
        opacity: 1;
    }

    #main_menu ul.navbar-nav > #to_home {
        height: auto;
    }

    #main_menu ul.navbar-nav > #to_home:hover {
        background-color: transparent;
    }

    #main_menu ul.navbar-nav > #to_home:hover a {
        background-color: transparent;
    }

    #main_menu ul.navbar-nav > #to_home img {
        position: relative;
        top: 5px;
        height: 22px;
    }

    #main_menu ul.navbar-nav > #to_home {
        position: absolute;
        top: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
    }

    #main_menu.affix ul.navbar-nav > #to_home {
        position: relative;
        visibility: visible;
        opacity: 1;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    #main_menu.affix ul.navbar-nav > li {

    }

    #main_menu.affix ul.navbar-nav > li > a {
        margin-top: -7px;
    }

    .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, .navbar-default .navbar-nav > .open > a {
        background-color: #7d5514;
        color: #fff;
    }
}

@media (min-width: 1200px) {
    #menu-ogolne .list-inline {
        margin-bottom: 2px;
        margin-top: 6px;
    }

    #wyszukiwarka {
        margin: 42px 0 0;
    }

    #wyszukiwarka form {
        margin: 0;
        padding: 0;
    }

    #wyszukiwarka .navbar-nav > li > a {
        padding: 1px 0 0 6px;
    }

    #wyszukiwarka .list-inline > li {
        padding: 0px 0 0 22px;
    }

    .navbar-default {
        background-color: #323582;
        border: 0 none;
    }

    .logo {
        padding-top: 2px;
    }

    .logo img {
        margin-bottom: 20px;
        /*margin-left: 10px;*/
    }

    #main_menu {
        margin-bottom: 0;
    }

    #main_menu .navbar-collapse {
        padding: 0;
    }

    #main_menu ul.navbar-nav > li {
        height: 60px;
    }

    #main_menu ul.navbar-nav > li > a {
        color: #FFF;
        padding-bottom: 0;
        padding-top: 19px;
    }

    #main_menu {
        height: 60px;
        min-height: 60px;
    }

    #main_menu ul.navbar-nav {
        border-bottom: 1px solid #000;
        border-top: 0px solid #000;
        clear: both;
        display: table;
        height: 40px;
        line-height: 40px;
        list-style: outside none none;
        margin: 0 0 1px;
        padding: 0;
        text-align: center;
        width: 100%;
    }

    #main_menu ul.navbar-nav > li {
        display: table-cell;
        float: none;
        font-family: 'Calibri', sans-serif;
        font-size: 16px;
    }

    #main_menu ul.navbar-nav ul {
        font-family: 'Calibri', sans-serif;
        background-color: #7d5514;
    }

    #main_menu ul.navbar-nav > li > a {
        color: #000;
        display: block;
        height: 100%;
        text-decoration: none;
        width: 100%;
    }

    #main_menu ul.navbar-nav > li:hover > a {
        background-color: #7d5514;
        color: #fff;
    }

    #main_menu ul.navbar-nav > li.active > a {
        background-color: #7d5514;
        color: #fff;
    }

    #main_menu ul.navbar-nav ul {
        border: 0 none;
        text-align: left;
    }

    .dropdown-menu {
        left: auto;
        top: inherit;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu > .dropdown-menu {
        border-radius: 0 6px 6px;
        left: 100%;
        margin-left: -1px;
        margin-top: -6px;
        top: 0;
    }

    .dropdown-submenu > a::after {
        border-color: transparent transparent transparent #FFF;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        content: " ";
        display: block;
        float: right;
        height: 0;
        margin-right: -10px;
        margin-top: 5px;
        width: 0;
    }

    .dropdown-submenu:hover > a::after {
        border-left-color: #FFF;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        border-radius: 6px 0 6px 6px;
        left: -100%;
        margin-left: 10px;
    }

    .blueimp-gallery .modal-dialog {
        padding-left: 0;
        padding-right: 0;
    }

    #main_menu.affix {
        border-bottom: 0 none;
        box-shadow: 0 0 15px #888;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }

    #main_menu.affix ul.navbar-nav {
        border: 0 none;
    }

    #main_menu .go_top {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    #main_menu.affix .go_top {
        position: absolute;
        right: 10px;
        top: 0;
        visibility: visible;
        opacity: 1;
    }

    #main_menu ul.navbar-nav > #to_home {
        height: auto;
    }

    #main_menu ul.navbar-nav > #to_home:hover {
        background-color: transparent;
    }

    #main_menu ul.navbar-nav > #to_home:hover a {
        background-color: transparent;
    }

    #main_menu ul.navbar-nav > #to_home img {
        position: relative;
        top: 5px;
        height: 22px;
    }

    #main_menu ul.navbar-nav > #to_home {
        position: absolute;
        top: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
    }

    #main_menu.affix ul.navbar-nav > #to_home {
        position: relative;
        vertical-align: middle;
        height: 60px;
        visibility: visible;
        opacity: 1;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    #main_menu.affix ul.navbar-nav > li {
        vertical-align: bottom;
    }

    #main_menu.affix ul.navbar-nav > li > a {
        margin-top: -5px;
    }

    #main_menu ul.navbar-nav > li > a:focus {
        background-color: #7d5514;
        color: #fff;
    }

    .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, .navbar-default .navbar-nav > .open > a {
        background-color: #FFF;
    }
}

@media print {

}

.navbar-default .navbar-toggle {
    border-color: #7d5514;
}
.navbar-toggle {
    position: relative;
    float: right;
    padding: 15px 15px;
    margin-right: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 2px solid #7d5514;
    border-radius: 0px;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #7d5514;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 27px;
    height: 4px;
    border-radius: 1px;
    background-color: transparent;
}
